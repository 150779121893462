<script>
  import IconBack from './icons/ArrowLeft.svelte';
  import IconForward from './icons/ArrowRight.svelte';

  export let type = 'primary'; // 'primary' or 'outlined'
  export let withIcon = true;
  export let iconForward = false;
  export let iconBack = false;
  export let onClick;
</script>

<button
  class={type}
  {withIcon}
  {iconBack}
  {iconForward}
  on:click={onClick}
  {...$$props}
  type="button">
  <!--// arrow icon left-->
  {#if withIcon && iconBack}
    <span class="btn-icon icon-back">
      <IconBack />
    </span>
  {/if}

  <!--// button content-->
  <span class="btn-content">
    <slot />
  </span>

  <!--// arrow icon right-->
  {#if withIcon && iconForward}
    <span class="btn-icon icon-forward">
      <IconForward />
    </span>
  {/if}
</button>

<style lang="scss">
  button {
    padding: 12px 10px 14px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    border: 1px solid $transparent;
    outline: none;
    width: 100%;
    cursor: pointer;

    &:active,
    &:focus {
      border: none;
      outline: none;
    }

    &:disabled,
    &:disabled:hover {
      &.primary {
        color: $color-white;
        background-color: $color-grey;
        cursor: not-allowed;
        @extend %border-disable;

        .btn-icon {
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(29deg)
            brightness(103%) contrast(102%);
        }
      }

      &.outlined {
        color: $color-grey;
        background-color: $color-white;
        cursor: not-allowed;
        @extend %border-disable;

        .btn-icon {
          filter: invert(92%) sepia(0%) saturate(240%) hue-rotate(193deg)
            brightness(88%) contrast(86%);
        }
      }
    }

    &.primary {
      color: $color-white;
      background-color: $color-black;
      border-color: $color-border-dark;

      &:hover {
        color: $color-black;
        background-color: $color-accent;
        @extend %border-accent-dark;

        .btn-icon {
          filter: invert(0%) sepia(7%) saturate(7470%) hue-rotate(11deg)
            brightness(112%) contrast(106%);
        }
      }

      .btn-icon {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(29deg)
          brightness(103%) contrast(102%);
      }
    }

    &.outlined {
      @extend %border;
      @extend %border-dark;
      color: $color-black;
      background-color: $transparent !important;

      &:hover {
        color: $color-black;
        background-color: $color-white;
        @extend %border-accent;

        .btn-icon {
          filter: invert(76%) sepia(90%) saturate(471%) hue-rotate(359deg)
            brightness(101%) contrast(110%);
        }
      }
    }

    .btn-icon {
      display: inline-block;

      &.icon-back {
        margin-right: 5px;
      }

      &.icon-forward {
        margin-left: 5px;
      }
    }

    .btn-content {
      display: inline-block;
      margin: 0 auto;
      @extend %font-bold;
      text-align: center;
    }
  }
</style>
