import { get, derived, readable, writable } from 'svelte/store';
import { gql, GraphQLClient } from 'graphql-request';
import { subscribeValue } from './utils/subscribe-value';
import { params } from './params';
import { url } from 'svelte-pathfinder';
import {
  REGION_REQUEST_DEBOUNCE_DELAY,
  API_HOST,
  GQL_API_TOKEN,
  OTK_PNAME,
  trailerNomPrefixes,
  uselessNomsSubstrings,
  ignoredLabs
} from './constants';
import Fuse from 'fuse.js';
import dayjs from 'dayjs';

const getLabs = gql`
  query getLabs($token: String!) {
    getLab(token: $token)
  }
`;

const getNoms = gql`
  query getNoms($token: String!, $ref: String, $jfilt: [JSON]) {
    list: noms(token: $token, ref: $ref, jfilt: $jfilt) {
      ref
      name
      name_full
    }
  }
`;

const getProj = gql`
  query dsGetProj($token: String!) {
    list: getProj(token: $token) {
      ref
      name
      predefined_name
    }
  }
`;

const getPrices = gql`
  query getPrices($token: String!, $date: String, $priceType: String!) {
    list: prices(token: $token, date: $date, priceType: $priceType) {
      nom
      price
    }
  }
`;

const paymentFormQuery = gql`
  query getForm($token: String, $orderData: JSONObject, $redirectUrl: String) {
    form: getPaymentForm(
      token: $token
      orderData: $orderData
      redirectUrl: $redirectUrl
    )
  }
`;

const createOrderQuery = gql`
  mutation createOrder($token: String, $input: JSONObject) {
    createOrder(token: $token, input: $input)
  }
`;

const gqlClient = new GraphQLClient(API_HOST, { headers: {} });

export const labStore = readable([], (set) => {
  gqlClient
    .request(getLabs, {
      token: GQL_API_TOKEN,
      jfilt: { fld: 'is_mobile_lab', expr: '<>', val: 'true' },
    })
    .then((result) => {
      if (!result?.errors) {
        return result.getLab
          .filter(lab => (
            !ignoredLabs.includes(lab.lab_number) &&
            lab.services?.length > 0
          )) || [];
      } else {
        throw new Error('Cannot get labs');
      }
    })
    .then((labs) => set(labs));
});

export const region = writable(null);
export const city = writable(null);

export const regionsStore = derived(labStore, ($labs) => (
  [...new Set($labs.map((lab) => lab?.contacts?.address?.region))].sort(
    (a, b) => a?.localeCompare(b)
  )
));

export const citiesStore = derived([labStore, region], ([$labs, $region]) => (
  [...new Set($labs
    .filter((lab) => !$region || $region === lab?.contacts?.address?.region)
    .map((lab) => lab?.contacts?.address?.city)
  )].sort((a, b) => a?.localeCompare(b))
));

let searchDebounceFn;
export const search = derived(
  subscribeValue(params, 'searchValue'),
  ($search, set) => {
    if (searchDebounceFn) {
      clearTimeout(searchDebounceFn);
    }
    searchDebounceFn = setTimeout(
      () => set($search),
      REGION_REQUEST_DEBOUNCE_DELAY
    );

    return () => {
      clearTimeout(searchDebounceFn);
    };
  }
);

const labDesc = (lab) =>
  `${lab?.lab_number},
   ${lab?.contacts?.address?.region || ''},
   ${lab?.contacts?.address?.city || ''},
   ${lab?.contacts?.address?.street || ''},
   ${lab?.contacts?.address?.house || ''}`;

export const selectedLab = derived(
  [subscribeValue(params, 'lab'), labStore],
  ([$lab, $labs]) => {
    const lab = $labs.find((lab) => lab.id === $lab);
    return lab ? { ...lab, desc: labDesc(lab) } : undefined;
  }
);

const projStore = readable(null, (set) => {
  gqlClient
    .request(getProj, { token: GQL_API_TOKEN })
    .then((projResult) => {
      if (!projResult?.errors) {
        set(projResult.list.find(
          (proj) => proj.predefined_name === OTK_PNAME
        ).ref);
      }
    })
});

export const nomsStore = derived(projStore, ($proj, set) => {
  if (!$proj) {
    set([]);
  } else {
    gqlClient
      .request(getNoms, {
        token: GQL_API_TOKEN,
        jfilt: {
          fld: 'proj',
          expr: '=',
          val: $proj,
        }
      })
      .then((nomsResult) => {
        if (!nomsResult?.errors) {
          set(nomsResult.list
            .filter((nom) => !uselessNomsSubstrings.some(
              (str) => nom.name.includes(str)))
            .map(
              (nom) => ({ ...nom, hint: nom.name_full })
            ));
        } else {
          throw new Error('Failed to load noms');
        }
      })
      .catch(() => set([]));
  }
}, []);

const filteredNoms = derived([nomsStore, selectedLab], ([$noms, $lab]) => {
  if ($lab) {
    const labNoms = $lab.services.map((service) => service.nom);
    return $noms.filter((nom) => labNoms.includes(nom.ref));
  }
  return $noms;
});

export const vehicleServicesStore = derived(filteredNoms, ($noms, set) => {
  set(
    $noms.filter(
      (nom) => !trailerNomPrefixes.some((prefix) => nom.name.includes(prefix))
    )
  );
});

export const trailerServicesStore = derived(filteredNoms, ($noms, set) => {
  set(
    $noms.filter((nom) =>
      trailerNomPrefixes.some((prefix) => nom.name.includes(prefix))
    )
  );
});

const vehicleCategory = subscribeValue(params, 'vehicle_category');
const trailerCategory = subscribeValue(params, 'trailer_category');

export const labListStore = derived(
  [vehicleCategory, trailerCategory, search, labStore, region, city],
  ([$vehicle, $trailer, $search, $labStore, $region, $city], set) => {
    const filterLabs = (labs) =>
      labs.filter((lab) => {
        const noms = lab.services.map((service) => service.nom);
        const address = lab?.contacts?.address;
        return (
          (!$city || address?.city === $city) &&
          (!$region || address?.region === $region) &&
          !(
            ($vehicle && !noms.includes($vehicle)) ||
            ($trailer && !noms.includes($trailer))
          )
        );
      });

    const mapLab = (lab) => ({
      id: lab.id,
      desc: labDesc(lab)
    });

    if ($search) {
      const options = {
        keys: [
          'desc'
        ],
        threshold: 0.4,
        ignoreLocation: true,
      };
      const fuse = new Fuse(filterLabs($labStore).map(mapLab), options);
      set(
        fuse
          .search(`${$search}`)
          .map((item) => item.item)
      );
    } else {
      set(filterLabs($labStore).map(mapLab));
    }
  },
  []
);

export const noPrice = writable({ value: false, id: null });

export const priceStore = derived(
  [selectedLab, vehicleCategory, trailerCategory],
  ([$lab, $vehicle, $trailer], set) => {
    if ($lab && ($vehicle || $trailer)) {
      gqlClient
        .request(getPrices, {
          token: GQL_API_TOKEN,
          date: new Date().toISOString(),
          priceType: $lab?.price_type
        })
        .then((result) => {
          if (!result.errors) {
            const prices = result.list;
            const vehicleServicePrice = prices.find(
              (price) => price.nom === $vehicle
            )?.price;
            const trailerServicePrice = prices.find(
              (price) => price.nom === $trailer
            )?.price;
            if (vehicleServicePrice || trailerServicePrice) {
              set({
                vehicle: vehicleServicePrice,
                trailer: trailerServicePrice,
                amount: (vehicleServicePrice || 0) + (trailerServicePrice || 0),
              });
              if (
                ($vehicle && !vehicleServicePrice) ||
                ($trailer && !trailerServicePrice)
              ) {
                throw new Error();
              } else {
                noPrice.set({ value: false, id: null });
              }
            } else {
              throw new Error();
            }
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          set(null);
          noPrice.set({ value: true, id: $lab?.id });
        });
    } else {
      set(null);
    }
  },
  null
);

export const prepareOrderData = () => {
  const vehicleService = get(vehicleServicesStore).find(
    (s) => s.ref === get(params).vehicle_category
  );
  const trailerService = get(trailerServicesStore).find(
    (s) => s.ref === get(params).trailer_category
  );

  const lab = get(selectedLab);

  const lab_info = labDesc(lab);

  const services = [ vehicleService?.name, trailerService?.name ]
    .filter((name) => !!name).join(' ');
  const desc = `Замовлення послуг: ${services}`;

  const newFields = {
    services,
    lab_info,
    lab_lat: lab?.latitude ?? '',
    lab_long: lab?.longitude ?? '',
  }
  params.update((prev) => ({
    ...prev,
    ...newFields,
  }));
  const infoLink = new URL(window.location.origin + get(url));
  const urlParams = new URLSearchParams(infoLink.search);
  Object.entries(newFields).forEach(([key, value]) => {
    urlParams.set(key, value);
  })
  infoLink.search = urlParams.toString();
  infoLink.pathname = '/order_info';

  const paramsObj = get(params);
  const date = dayjs(paramsObj.date)
    .set('hour', paramsObj.time.substring(0, 2))
    .set('minute', paramsObj.time.substring(3, 5))
    .toISOString()

  const prices = get(priceStore);
  return {
    ...paramsObj,
    order_date: new Date().toISOString(),
    date,
    vehicle_service: vehicleService ? {
      nom: vehicleService.ref,
      price: prices.vehicle,
      gos_code: String(paramsObj.vehicle_no),
    } : null,
    trailer_service: trailerService ? {
      nom: trailerService.ref,
      price: prices.trailer,
      gos_code: String(paramsObj.trailer_no),
    } : null,
    description: desc,
    lab: {
      _id: lab._id,
      department: lab.department,
      organization: lab.organization,
    },
    proj: get(projStore),
    phone_number: (paramsObj.phone_number)?.replace(/\D/g, '') ?? '',
    order_info_link: infoLink.toString(),
    person_name: String(paramsObj.person_name),
    inn: String(paramsObj.inn),
  };
};

export const getPaymentForm = (redirectUrl) =>
  gqlClient
    .request(paymentFormQuery, {
      token: GQL_API_TOKEN,
      orderData: prepareOrderData(),
      redirectUrl
    })
    .then((response) => {
      if (!response?.errors) {
        return response.form;
      } else {
        throw new Error('Cannot get a payment form');
      }
    });

export const createOrder = () =>
  gqlClient
    .request(createOrderQuery, {
      token: GQL_API_TOKEN,
      input: prepareOrderData()
    })
    .then((response) => {
      if (response?.errors) {
        throw new Error('Failed to create an order');
      }
      return response.createOrder;
    });
