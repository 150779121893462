export const API_HOST = 'https://site.sub.otk.in.ua';
//export const API_HOST = 'https://api-ext-sm.otk.in.ua';
//export const API_HOST = 'http://localhost:4001/site';
export const GQL_API_TOKEN = '0123';

export const CALL_CENTER_NUMBER = '+38(067)647-02-02';
export const EMAIL_VALIDATE_REGEX =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const INN_VALIDATE_REGEX = /^[0-9]{8,}$/;
export const SERVICE_HELP_LINK = 'https://www.google.com/';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const OTK_PNAME = 'отк';
export const MODAL_HIDE_TIMEOUT = 3000;
export const MAX_TIME = '16:30';
export const MIN_TIME = '09:00';

export const REGION_REQUEST_DEBOUNCE_DELAY = 0;
export const personTypes = {
  individual: 'individual',
  legal: 'legal'
};

export const paymentTypes = {
  cash: 'cash',
  cashless: 'cashless',
  online: 'online'
};

export const trailerNomPrefixes = ['O', 'О'];
export const uselessNomsSubstrings = ['G', 'ЄКМТ'];
export const ignoredLabs = [733, 1255, 754];

