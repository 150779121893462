import { derived } from 'svelte/store';

export const subscribeValue = (store, field) => {
  let oldValue;
  const valueStore = derived(store, ($store, set) => {
    if (oldValue !== $store[field]) {
      set($store[field]);
    }
  });

  return valueStore;
};
