<script>
  import { params } from '../params';
  import { personTypes } from '../constants';
</script>

<div class="container">
  <div class="person">
    <input
      type="radio"
      id="legal"
      bind:group={$params.person_type}
      value={personTypes.legal}
      checked />
    <label for="legal">Юридична особа</label>
  </div>
  <div class="person">
    <input
      type="radio"
      id="individual"
      bind:group={$params.person_type}
      value={personTypes.individual}
      on:change={() => ($params.inn = '')} />
    <label for="individual">Фізична особа</label>
  </div>
</div>

<style lang="scss">
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .person {
    flex: 1 1 50%;
  }

  @media screen and (max-width: 420px) {
    .person {
      flex-basis: 100%;
    }
  }

  input {
    &[type='radio']:checked,
    &[type='radio']:not(:checked) {
      position: absolute;
      left: -9999px;
    }

    &[type='radio']:checked + label,
    &[type='radio']:not(:checked) + label {
      position: relative;
      padding-left: rem(25);
      cursor: pointer;
      display: inline-block;
      @extend %text;
    }

    &[type='radio']:checked + label {
      @extend %text-bold;
    }

    &[type='radio']:checked + label:before,
    &[type='radio']:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: rem(13);
      height: rem(13);
      @extend %border;
      @extend %border-gray-dark;
      border-radius: 100%;
      background: $color-white;
    }

    &[type='radio']:checked + label:after,
    &[type='radio']:not(:checked) + label:after {
      content: '';
      width: rem(9);
      height: rem(9);
      background: $color-accent;
      position: absolute;
      top: rem(3);
      left: rem(3);
      border-radius: 100%;
      transition: all 0.2s ease;
    }

    &[type='radio']:not(:checked) + label:after {
      opacity: 0;
      transform: scale(0);
    }

    &[type='radio']:checked + label:after {
      opacity: 1;
      transform: scale(1);
    }
  }
</style>
