<script>
  import Select from 'svelte-select';
  import { params } from '../params';
  import dayjs from 'dayjs';
  import { MAX_TIME, MIN_TIME } from '../constants';
  import {
    vehicleServicesStore,
    trailerServicesStore,
    nomsStore,
  } from '../api';
  import {
    fieldValidator,
    validators,
    validationGroup
  } from '../utils/validator';

  import IconAuto from './ui/icons/Auto.svelte';
  import IconPryc from './ui/icons/Pryc.svelte';
  import IconCalendar from './ui/icons/Calendar.svelte';
  import SelectItem from './ui/SelectItem.svelte';
  import Clock from './ui/icons/Clock.svelte';

  const vehicleCategoryValidator = fieldValidator();
  const vehicleNoValidator = fieldValidator(validators.required());
  const trailerNoValidator = fieldValidator(validators.required());

  $: vehicleCategoryValidator.validate(null, $params.vehicle_category);

  $: group = validationGroup({
    vehicleCategoryValidator,
    ...($params.vehicle_category ? { vehicleNoValidator } : {}),
    ...($params.trailer_category ? { trailerNoValidator } : {})
  });

  export const validate = () => {
    const errors = group.validate();
    return Object.keys(errors).length === 0;
  };
</script>

<div class="service-row">
  <div class="form-row">
    <Select
      Icon={IconAuto}
      Item={SelectItem}
      showIndicator
      value={$vehicleServicesStore.find(
        (service) => service.ref === $params.vehicle_category
      )}
      hasError={$vehicleCategoryValidator.showMessage}
      items={$vehicleServicesStore}
      optionIdentifier={'name'}
      labelIdentifier={'name'}
      placeholder={'Виберіть автомобіль'}
      noOptionsMessage={'Обрана лабораторія не робить даний тип послуг'}
      hideEmptyState={!$nomsStore.length}
      on:clear={() => ($params.vehicle_category = null)}
      on:select={(e) => ($params.vehicle_category = e.detail.ref)} />

    <p class="error-msg" class:hidden={!$vehicleCategoryValidator.showMessage}>
      {$vehicleCategoryValidator.message}
    </p>
  </div>
  <div class="form-row">
    <input
      id="vehicle_no"
      class:invalid={$vehicleNoValidator.showMessage}
      bind:value={$params.vehicle_no}
      use:vehicleNoValidator.validate={$params.vehicle_no}
      placeholder="Держ. номер Авто " />
    <p class="error-msg" class:hidden={!$vehicleNoValidator.showMessage}>
      {$vehicleNoValidator.message}
    </p>
  </div>
</div>

<div class="service-row">
  <div class="form-row">
    <Select
      Icon={IconPryc}
      Item={SelectItem}
      showIndicator
      value={$trailerServicesStore.find(
        (service) => service.ref === $params.trailer_category
      )}
      items={$trailerServicesStore}
      optionIdentifier={'name'}
      labelIdentifier={'name'}
      placeholder={'Виберіть причіп'}
      noOptionsMessage={'Обрана лабораторія не робить даний тип послуг'}
      hideEmptyState={!$nomsStore.length}
      on:clear={() => ($params.trailer_category = null)}
      on:select={(e) => ($params.trailer_category = e.detail.ref)} />
  </div>
  <div class="form-row">
    <input
      id="trailer_no"
      class:invalid={$trailerNoValidator.showMessage}
      bind:value={$params.trailer_no}
      use:trailerNoValidator.validate={$params.trailer_no}
      placeholder="Держ. номер Причепа " />
    <p class="error-msg" class:hidden={!$trailerNoValidator.showMessage}>
      {$trailerNoValidator.message}
    </p>
  </div>
</div>

<div class="datetime">
  <div class="form-row">
    <label for="date" class="field-label">Виберіть бажану дату сервісу</label>
    <div class="input-icon">
      <div class="icon">
        <IconCalendar />
      </div>
      <input
        id="date"
        class="field"
        type="date"
        bind:value={$params.date}
        min={dayjs().startOf('day').format('YYYY-MM-DD')} />
    </div>
  </div>

  <div class="form-row">
    <label for="time" class="field-label">Виберіть час</label>
    <div class="input-icon">
      <div class="icon">
        <Clock />
      </div>
      <input
        id="time"
        class="field"
        type="time"
        min="9:00"
        max="16:30"
        bind:value={$params.time}
        on:input={(e) => {
          const value = e.target.value;
          console.log(value);
          if (value > MAX_TIME) {
            $params.time = MAX_TIME;
          } else if (value < MIN_TIME) {
            $params.time = MIN_TIME;
          }
        }} />
    </div>
  </div>
</div>

<style lang="scss">
  input {
    @extend %input;
    width: 100%;
    line-height: rem(20);
    margin: 0;
    padding: rem(10);

    &[type='number']::-webkit-outer-spin-button,
    &[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .input-icon input {
    padding-left: rem(50);
  }

  .services {
    display: flex;
    gap: rem(10);
  }

  .service-row {
    display: flex;
    gap: rem(10);
  }

  .datetime {
    display: flex;
    gap: rem(10);
  }

  .field-label {
    @extend %text;
    @extend %text-left;
    margin-bottom: rem(5);
    font-size: rem(12);
  }

  .field {
    @extend %w-full;
    margin: 0;
    padding-left: 50px;
  }

  .hidden {
    display: none;
  }

  .error-msg {
    @extend %error-msg;
  }

  .info-block {
    @extend %text-right;

    .help-link {
      @extend %link;
      margin-top: rem(10);
      margin-bottom: rem(5);
      display: inline-block;
      text-decoration: underline;
    }
  }
</style>
