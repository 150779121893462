<script>
  export let title = '';
  let isHovered = false;
  let x;
  let y;
  const offsetX = -125;
  const offsetY = 15;

  function mouseOver(event) {
    isHovered = true;
    x = event.pageX + offsetX;
    y = event.pageY + offsetY;
  }
  function mouseMove(event) {
    x = event.pageX + offsetX;
    y = event.pageY + offsetY;
  }
  function mouseLeave() {
    isHovered = false;
  }
</script>

<div
  class="container"
  on:mouseover={mouseOver}
  on:mouseleave={mouseLeave}
  on:mousemove={mouseMove}
  on:focus>
  <slot />
  {#if isHovered}
    <div style="top: {y}px; left: {x}px;" class="tooltip">{title}</div>
  {/if}
</div>

<style lang="scss">
  .container {
    width: fit-content;
    height: fit-content;
  }

  .tooltip {
    position: fixed;
    background: $color-dark;
    text-align: left;
    color: $color-white;
    padding: 4px 2px;
    font-size: 12px;
    width: 160px;
    border-radius: 5px;
    pointer-events: none;
    padding: 4px 4px;
    z-index: 99;
    max-width: 400px;
    line-height: 1rem;
    white-space: normal;

    &::before {
      content: '';
      position: absolute;
      border-width: 4px 6px 0 6px;
      border-style: solid;
      border-color: $color-dark transparent transparent transparent;
      z-index: 99;
      transform: translateX(120px) translateY(-7px) rotate(-180deg);
    }

    &::after {
      content: attr(tooltip);
      position: absolute;
    }

    &:hover::after,
    &:hover::before {
      opacity: 1;
      transition-delay: 1s;
      transition: all 0.5s;
    }
  }
</style>
