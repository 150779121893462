<div class="container">
  <svg
    width="100"
    height="100"
    viewBox="0 0 195 195"
    fill="#ffed00"
    xmlns="http://www.w3.org/2000/svg">
    <path
      stroke-width="6"
      clip-rule="evenodd"
      stroke-miterlimit="10"
      d="m 6.99 59.29 l 53.51 -52.3 l 76.44 0 l 53.41 54.84 l 0 73.96 l -53.41 52.39 l -75.26 0 l -54.69 -53.96 l 0 -74.93 Z"
      stroke="black"
      fill-rule="evenodd" />
  </svg>
  <div class="content">
    <slot></slot>
  </div>
</div>

<style lang="scss">
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
  }

  .content {
    position: absolute;
  }
</style>
