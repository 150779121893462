<script>
  import { pattern } from 'svelte-pathfinder';
  import OrderForm from './components/OrderForm.svelte';
  import OrderInfo from './components/OrderInfo.svelte';
  import CallcenterForm from './components/CallcenterForm.svelte';
  import CallcenterOrderSummary from './components/CallcenterOrderSummary.svelte';
  import Modal from 'svelte-simple-modal';
</script>

<main>
  {#if $pattern('callcenter_form')}
    <Modal>
      <CallcenterForm />
    </Modal>
  {:else if $pattern('callcenter_order_summary')}
    <CallcenterOrderSummary />
  {:else}
    <div class="container">
      {#if $pattern('order_info')}
        <OrderInfo />
      {:else}
        <Modal>
          <OrderForm />
        </Modal>
      {/if}
    </div>
  {/if}
</main>

<style lang="scss">
  :global(body) {
    font-family: $font-regular;
    font-size: $BASE-PIXEL-VALUE + px;
    padding: 0;
  }

  main {
    margin: auto;
  }

  * {
    box-sizing: border-box;
  }

  .container {
    margin: 0 auto;
    padding: rem(15) rem(30);
    max-width: rem(500);
    background-color: $color-bg;
    position: relative;
  }
</style>
