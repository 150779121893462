<svg
  width="15"
  height="15"
  viewBox="0 0 15 15"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M0.170967 5.6684C0.412819 6.93851 1.13074 9.38144 3.22101 11.4717C5.31127 13.562 7.7542 14.2801 9.0245 14.5219C10.479 14.7989 12.6136 14.8407 13.6567 13.7975L14.2382 13.216C14.5318 12.9224 14.6935 12.532 14.6935 12.1168C14.6935 11.7015 14.5318 11.3113 14.2382 11.0178L11.9126 8.69212C11.6189 8.39839 11.2286 8.23684 10.8134 8.23684C10.3982 8.23684 10.0079 8.39839 9.7142 8.69212L9.13284 9.27348C8.77769 9.62863 8.10725 9.63016 7.74944 9.27787L5.42972 6.86088C5.42629 6.85725 5.42285 6.85363 5.41923 6.85019C5.06351 6.49447 5.06351 5.91559 5.41923 5.55987L6.00059 4.97851C6.60827 4.37083 6.60827 3.38759 6.00059 2.7801L3.67496 0.454473C3.06899 -0.151491 2.08289 -0.151491 1.47674 0.454473L0.895378 1.03602V1.03583C0.0628201 1.86858 -0.214699 3.64356 0.170967 5.6684ZM1.93088 2.07152C2.54123 1.4751 2.50861 1.46365 2.57594 1.46365C2.59902 1.46365 2.6221 1.47243 2.63965 1.48997C5.09021 3.9537 4.99179 3.80893 4.99179 3.8795C4.99179 3.9041 4.98283 3.92566 4.96547 3.9432L4.38392 4.52456C3.4579 5.45058 3.45428 6.94957 4.37801 7.87978L6.69906 10.2983C6.70268 10.3017 6.70612 10.3054 6.70974 10.309C7.63557 11.2348 9.23946 11.2379 10.1683 10.309L10.7497 9.72762C10.7848 9.69253 10.842 9.69253 10.8771 9.72762C13.3277 12.1912 13.2292 12.0464 13.2292 12.117C13.2292 12.1418 13.2203 12.1631 13.2029 12.1807L12.6214 12.762C12.2229 13.1605 10.9538 13.3989 9.29839 13.0836C8.19366 12.873 6.06944 12.2493 4.25651 10.4364C2.44357 8.62346 1.81987 6.49924 1.60949 5.39451C1.29401 3.73912 1.53243 2.46978 1.93088 2.07152Z"
    fill="black" />
</svg>
