<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M10.125 5.07086V5.46973L10.5139 5.55836C11.5429 5.79288 12.3125 6.71562 12.3125 7.8125C12.3125 7.88153 12.2565 7.9375 12.1875 7.9375C12.1185 7.9375 12.0625 7.88153 12.0625 7.8125C12.0625 6.67479 11.1377 5.75 10 5.75C8.8623 5.75 7.9375 6.67479 7.9375 7.8125C7.9375 8.9502 8.8623 9.875 10 9.875C11.2747 9.875 12.3125 10.9128 12.3125 12.1875C12.3125 13.2844 11.5429 14.2071 10.5139 14.4416L10.125 14.5303V14.9291V15.625C10.125 15.694 10.069 15.75 10 15.75C9.93097 15.75 9.875 15.694 9.875 15.625V14.9291V14.5303L9.4861 14.4416C8.45708 14.2071 7.6875 13.2844 7.6875 12.1875C7.6875 12.1185 7.74347 12.0625 7.8125 12.0625C7.88153 12.0625 7.9375 12.1185 7.9375 12.1875C7.9375 13.3252 8.8623 14.25 10 14.25C11.1377 14.25 12.0625 13.3252 12.0625 12.1875C12.0625 11.0498 11.1377 10.125 10 10.125C8.72532 10.125 7.6875 9.08718 7.6875 7.8125C7.6875 6.71562 8.45708 5.79288 9.4861 5.55836L9.875 5.46973V5.07086V4.375C9.875 4.30597 9.93097 4.25 10 4.25C10.069 4.25 10.125 4.30597 10.125 4.375V5.07086ZM10 19.5C7.46151 19.5 5.07744 18.5125 3.28246 16.7175C1.48748 14.9226 0.5 12.5385 0.5 10C0.5 7.46152 1.48752 5.07748 3.28247 3.28245C5.0774 1.48745 7.4615 0.5 10 0.5C12.5385 0.5 14.9226 1.48748 16.7175 3.28246C18.5125 5.07744 19.5 7.46151 19.5 10C19.5 12.5385 18.5125 14.9225 16.7175 16.7175C14.9226 18.5126 12.5385 19.5 10 19.5ZM10 0.75C4.89909 0.75 0.75 4.89909 0.75 10C0.75 15.1009 4.89909 19.25 10 19.25C15.1009 19.25 19.25 15.1009 19.25 10C19.25 4.89909 15.1009 0.75 10 0.75Z"
    fill="black"
    stroke="black" />
</svg>
