<script>
  import { path, pattern } from 'svelte-pathfinder';
  import { createOrder, getPaymentForm, noPrice } from '../api';
  import OrderStep1 from './OrderStep1.svelte';
  import OrderStepFinal from './OrderStepFinal.svelte';
  import OrderAmount from './OrderAmount.svelte';

  import { labStore, nomsStore, priceStore } from '../api';
  import { onDestroy } from 'svelte';
  import { holdStore } from '../utils/hold-store';
  import { params } from '../params';
  import Button from './ui/Button.svelte';
  import ErrorPopup from './ErrorPopup.svelte';

  import { getContext } from 'svelte';
  import { MODAL_HIDE_TIMEOUT } from '../constants';
import OrderStepLab from './OrderStepLab.svelte';

  const { open, close } = getContext('simple-modal');

  let stopHold;
  $: if (!$pattern('order_summary')) {
    stopHold = holdStore(labStore, priceStore, nomsStore);
  }

  onDestroy(() => {
    stopHold?.();
  });

  let isButtonEnabled = true;

  let stepLeft;
  let step1;
  let stepLab;
  let paymentForm = null;
  $: isButtonEnabled = $params.order_amount;

  const handleClick = () => {
    if (stepLeft.validate() && step1.validate() && stepLab.validate()) {
      if ($params.payment_type === 'online') {
        const redirectUrl = new URL(window.location.href);
        redirectUrl.pathname = 'order_summary';
        getPaymentForm(redirectUrl.href)
          .then((form) => {
            paymentForm = form;
            setTimeout(() => {
              document.getElementById('payment_form').submit();
            }, 0);
          })
          .catch(() => {
            paymentForm = null;
            open(ErrorPopup, { message: 'Виберіть інший спосіб оплати' });
            setTimeout(() => close(), MODAL_HIDE_TIMEOUT);
          });
      } else {
        isButtonEnabled = false;
        createOrder()
          .then((order) => {
            $params.order_id = order.order_id;
            isButtonEnabled = true;
            $path = '/callcenter_order_summary';
          })
          .catch(() => {
            isButtonEnabled = true;
            open(ErrorPopup, {
              message: `Перевірте правильність ЄДРПОУ, 
                або виберіть "фізична особа" якщо не бажаєте вказувати ЄДРПОУ`
            });
          });
      }
    }
  };

  $: if ($noPrice.value && $params.lab === $noPrice.id && $pattern('/step1')) {
    open(ErrorPopup, { message: 'Дана лабораторія не надає обраних послуг' });
    setTimeout(() => close(), MODAL_HIDE_TIMEOUT);
  }
</script>

<form class="form">
  <div class='step-container'>
    <div class='step-left'>
      <OrderStepFinal bind:this={stepLeft}/>
    </div>
    <div class='step-right'>
      <OrderStep1 bind:this={step1}/>
      <OrderStepLab bind:this={stepLab}/>
    </div>
  </div>
  <div class='final-block'>
    <OrderAmount />
    <Button
      withIcon
      iconForward
      disabled={!isButtonEnabled}
      onClick={handleClick}
      >Оформити замовлення
    </Button>
  </div>
</form>

<style lang="scss">
  .form {
    max-width: rem(900);
    margin: auto;
    background-color: $color-bg;
  }
  .step-container {
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }

  .step-left {
    width: rem(350);
  }

  .step-right {
    width: rem(500);
    margin-top: rem(80);
  }

  .final-block {
    padding-top: rem(30);
    max-width: rem(250);
    margin: auto;
  }
</style>
