<svg
  width="18"
  height="23"
  viewBox="0 0 18 23"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M2.48375 4.46889C0.993217 6.6261 0.644142 9.35749 1.55811 11.7764C1.79565 12.4174 2.17448 13.0324 2.67463 13.6123C2.67502 13.6127 2.6754 13.6132 2.67579 13.6136L8.61955 20.595L9.00026 21.0422L9.38097 20.595L15.3182 13.6215L15.3248 13.6138C15.8255 13.0334 16.2048 12.418 16.4406 11.7816C17.3566 9.3577 17.0074 6.62624 15.5165 4.46885C14.0189 2.30139 11.6398 1.05584 9.00026 1.05584C6.36064 1.05584 3.98128 2.30142 2.48375 4.46889ZM2.48375 4.46889L2.89511 4.75312L2.48375 4.46889ZM15.9737 4.15257L15.9738 4.1526C17.5722 6.46563 17.9387 9.38999 16.9614 11.9743L16.9602 11.9776C16.7092 12.6554 16.3032 13.3296 15.7467 13.9747L15.7445 13.9772L9.21178 21.6506L9.21162 21.6508C9.15881 21.7129 9.08151 21.7486 9.00026 21.7486C8.91907 21.7486 8.84173 21.7129 8.78887 21.6508L8.78874 21.6506L2.25417 13.9751L2.25418 13.9751L2.25181 13.9724C1.69828 13.3316 1.29166 12.6568 1.03827 11.9726L1.03828 11.9726L1.03707 11.9694C0.0618378 9.39015 0.428251 6.46576 2.02676 4.1526L2.02678 4.15257C3.63168 1.82983 6.1696 0.5 9.00026 0.5C11.8309 0.5 14.3688 1.82983 15.9737 4.15257ZM15.2998 13.6407L15.3009 13.6396L15.2998 13.6407ZM5.14388 8.97205C5.14388 6.84597 6.8744 5.11567 9.00026 5.11567C11.1261 5.11567 12.8566 6.84622 12.8566 8.97205C12.8566 11.0982 11.1264 12.8284 9.00026 12.8284C6.87416 12.8284 5.14388 11.0982 5.14388 8.97205ZM5.69972 8.97201C5.69972 10.7923 7.17993 12.2726 9.00026 12.2726C10.8206 12.2726 12.3008 10.7923 12.3008 8.97201C12.3008 7.15167 10.8206 5.67147 9.00026 5.67147C7.17993 5.67147 5.69972 7.15167 5.69972 8.97201Z"
    fill="black"
    stroke="black" />
</svg>
