import { derived, writable, get } from 'svelte/store';
import { EMAIL_VALIDATE_REGEX, INN_VALIDATE_REGEX } from '../constants';

const buildValidator = (validators) => {
  return function validate(value, dirty) {
    if (!validators || validators.length === 0) {
      return { dirty, valid: true };
    }

    const failing = validators.find((v) => v(value) !== true);

    return {
      dirty,
      valid: !failing,
      message: failing && failing(value)
    };
  };
};

export const fieldValidator = (...validators) => {
  const { subscribe, update } = writable({
    dirty: false,
    valid: false,
    message: null,
    showMessage: false
  });
  const validator = buildValidator(validators.filter((f) => !!f));

  function action(node, binding) {
    function validate(value, dirty) {
      const result = validator(value, dirty);
      update((prev) => ({
        ...prev,
        ...result,
        showMessage: prev.showMessage && !result.valid
      }));
    }

    validate(binding, false);

    return {
      update(value) {
        validate(value, true);
      }
    };
  }

  const toggleMessage = (shown) => {
    update((prev) => ({ ...prev, showMessage: shown && !prev.valid }));
  };

  return {
    subscribe,
    validate: action,
    toggleMessage
  };
};

export const validationGroup = (validators) => {
  const isValid = derived(Object.values(validators), ($validators) =>
    $validators.every((v) => v.valid)
  );
  const showErrors = () => {
    Object.values(validators).forEach((validator) =>
      validator.toggleMessage(true)
    );
  };

  const getErrors = () => {
    return Object.fromEntries(
      Object.entries(validators)
        .map(([key, value]) => [key, get(value).message])
        .filter(([, msg]) => !!msg)
    );
  };

  const validate = () => {
    showErrors();
    return getErrors();
  };

  return {
    ...isValid,
    showErrors,
    getErrors,
    validate
  };
};

const regexValidator = (regex, msg) => (value) =>
  (value && !!String(value).match(regex)) || msg;

const emailValidator = (msg = 'Невірний email') =>
  regexValidator(EMAIL_VALIDATE_REGEX, msg);

const innValidator = (msg = 'Невірний ИНН') =>
  regexValidator(INN_VALIDATE_REGEX, msg);

const requiredValidator =
  (msg = 'Потрібно заповнити поле') =>
    (value) =>
      (value !== undefined && value !== null && value !== '') || msg;

export const validators = {
  email: emailValidator,
  required: requiredValidator,
  inn: innValidator
};
