<script>
  import { path, pattern } from 'svelte-pathfinder';
  import { createOrder, getPaymentForm, noPrice } from '../api';
  import OrderStep1 from './OrderStep1.svelte';
  import OrderStepLab from './OrderStepLab.svelte';
  import OrderStepFinal from './OrderStepFinal.svelte';
  import OrderAmount from './OrderAmount.svelte';
  import OrderSummary from './OrderSummary.svelte';

  import { labStore, nomsStore, priceStore } from '../api';
  import { onDestroy } from 'svelte';
  import { holdStore } from '../utils/hold-store';
  import { params } from '../params';
  import Button from './ui/Button.svelte';
  import ErrorPopup from './ErrorPopup.svelte';

  import { getContext } from 'svelte';
  import { MODAL_HIDE_TIMEOUT } from '../constants';

  const { open, close } = getContext('simple-modal');

  let stopHold;
  $: if (!$pattern('order_summary')) {
    stopHold = holdStore(labStore, priceStore, nomsStore);
  }

  onDestroy(() => {
    stopHold?.();
  });

  let isButtonEnabled = true;

  let child;
  let paymentForm = null;
  $: isButtonEnabled = !paymentForm;

  const handleStep1Click = () => {
    if (child.validate()) {
      $path = '/lab_select';
    }
  };

  const handleStepLabClick = () => {
    if (child.validate()) {
      $path = '/final_step';
    }
  }

  const handleFinalStepClick = () => {
    if (child.validate()) {
      if ($params.payment_type === 'online') {
        const redirectUrl = new URL(window.location.href);
        redirectUrl.pathname = 'order_summary';
        getPaymentForm(redirectUrl.href)
          .then((form) => {
            paymentForm = form;
            setTimeout(() => {
              document.getElementById('payment_form').submit();
            }, 0);
          })
          .catch(() => {
            paymentForm = null;
            open(ErrorPopup, { message: 'Виберіть інший спосіб оплати' });
            setTimeout(() => close(), MODAL_HIDE_TIMEOUT);
          });
      } else {
        isButtonEnabled = false;
        createOrder()
          .then((order) => {
            $params.order_id = order.order_id;
            isButtonEnabled = true;
            $path = '/order_summary';
          })
          .catch(() => {
            isButtonEnabled = true;
            open(ErrorPopup, {
              message: `Перевірте правильність ЄДРПОУ, 
                або виберіть "фізична особа" якщо не бажаєте вказувати ЄДРПОУ`
            });
          });
      }
    }
  };

  const stepComponents = {
    '/step1': OrderStep1,
    '/lab_select': OrderStepLab,
    '/final_step': OrderStepFinal, 
    '/order_summary': OrderSummary,
  };

  $: if (!($pattern('/step1') ||
    $pattern('/lab_select') ||
    $pattern('/final_step') || 
    $pattern('/order_summary')
  )) {
    $path = '/step1';
  }

  $: if ($noPrice.value && $params.lab === $noPrice.id && $pattern('/step1')) {
    open(ErrorPopup, { message: 'Дана лабораторія не надає обраних послуг' });
    setTimeout(() => close(), MODAL_HIDE_TIMEOUT);
  }
</script>

{#if $pattern('/step1')}
  <h1 class="step-title-primary">Замовлення</h1>
{/if}
<form class="form">
  <svelte:component this={stepComponents[$path]} bind:this={child} />
  {#if paymentForm}
    <div class="payment-form">
      {@html paymentForm}
    </div>
  {/if}

  {#if $pattern('/step1') || $pattern('/lab_select') || $pattern('final_step')}
    <OrderAmount />
  {/if}

  <div class="button-group">
    {#if $pattern('/step1')}
      <Button
        withIcon
        iconForward
        disabled={!($params.vehicle_category || $params.trailer_category)}
        onClick={handleStep1Click}
        >Вибрати лабораторію
      </Button>
    {/if}

    {#if $pattern('/lab_select')}
      <Button
        type="outlined"
        withIcon
        iconBack
        onClick={() => ($path = '/step1')}
        >Назад
      </Button>
      <Button
        withIcon
        iconForward
        disabled={!$params.order_amount}
        onClick={handleStepLabClick}
        >Останній крок
      </Button>
    {/if}

    {#if $pattern('/final_step')}
      <Button
        type="outlined"
        withIcon
        iconBack
        onClick={() => ($path = '/lab_select')}
        >Назад
      </Button>
      <Button
        withIcon
        iconForward
        disabled={!isButtonEnabled}
        onClick={handleFinalStepClick}
        >Замовити
      </Button>
    {/if}
  </div>

</form>

<style lang="scss">
  .form {
    background-color: $color-bg;
  }

  .step-title-primary {
    @extend %title-primary;
    @extend %text-left;
    margin: rem(15) 0 rem(25);
  }

  .step-indicators {
    margin: auto;
    padding: 0 0 rem(50);
    max-width: rem(130);
    @extend %d-flex;
    @extend %align-center;
    @extend %w-full;
    justify-content: space-between;
    list-style-type: none;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .step-indicator {
    @extend %d-flex;
    @extend %align-center;
    flex: 1 0;
    position: relative;

    &::before {
      content: '';
      display: inline-block;
      width: rem(7);
      height: rem(7);
      background-color: $color-white;
      @extend %border;
      @extend %border-gray-dark;
      border-radius: 100%;
      box-shadow: 0 0 0 rem(4) $color-white;
      position: relative;
      z-index: 2;
    }

    &.filled {
      &::before {
        background-color: $color-accent;
        @extend %border-accent;
      }

      &::after,
      &:not(.checked)::after {
        background-color: $color-accent;
      }
    }

    &.active {
      &::before {
        width: rem(12);
        height: rem(12);
        background-color: $color-accent;
        box-shadow: 0 0 0 rem(2) $color-white;
        @extend %border-accent;
      }
    }

    &.checked {
      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: '✓';
        z-index: 2;
      }
    }

    &:hover {
      cursor: pointer;
    }

    &:last-child {
      flex: 0;
    }

    &:not(.checked)::after {
      content: '';
      display: flex;
      flex: 1 0;
      height: 1px;
      background-color: $gray;
    }
  }

  .payment-form {
    display: none;
  }

  .button-group {
    @extend %d-flex;
    @extend %align-center;
    margin-top: rem(10);
  }
</style>
