<svg
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M0.514344 17.5C0.634211 15.417 1.50191 13.4773 2.98958 11.9896C3.9164 11.0628 5.0181 10.3779 6.21915 9.9635L7.15494 9.64059L6.33966 9.07907C5.10539 8.22896 4.29688 6.80817 4.29688 5.20312C4.29688 2.61027 6.40715 0.5 9 0.5C11.5929 0.5 13.7031 2.61027 13.7031 5.20312C13.7031 6.80817 12.8946 8.22896 11.6603 9.07907L10.845 9.64058L11.7808 9.9635C12.9819 10.3779 14.0836 11.0628 15.0104 11.9896C16.4981 13.4773 17.3658 15.417 17.4857 17.5H17.0785C16.8194 13.2687 13.2955 9.90625 9 9.90625C4.70454 9.90625 1.18055 13.2687 0.921512 17.5H0.514344ZM9 9.5C11.3697 9.5 13.2969 7.5729 13.2969 5.20312C13.2969 2.83335 11.3697 0.90625 9 0.90625C6.63027 0.90625 4.70312 2.83335 4.70312 5.20312C4.70312 7.5729 6.63027 9.5 9 9.5Z"
    fill="black"
    stroke="black" />
</svg>
