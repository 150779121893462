<script>
  import { path, query } from 'svelte-pathfinder';
  import OrderDescription from './OrderDescription.svelte';
  import Button from './ui/Button.svelte';

  const handleClick = () => {
    $path = '/callcenter_form';
    $query = '';
  }
</script>

<div class="container">
  <h2>Замовлення створено</h2>
  <OrderDescription />
  <div class="button">
    <Button
      type="outlined"
      onClick={handleClick}
      >Нове замовлення
    </Button>
  </div>
</div>

<style lang="scss">
  .container {
    width: rem(400);
    margin: auto;
  }

  .button {
    width: rem(200);
    margin: auto;
    margin-top: rem(50);
  }

  h2 {
    text-align: center;
  }
</style>