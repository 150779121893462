<script>
  import OrderDescription from './OrderDescription.svelte';
  import OrderSummaryHeader from './OrderSummaryHeader.svelte';
  import LocationInfo from './LocationInfo.svelte';
  import Call from './Call.svelte';
</script>

<OrderSummaryHeader />
<OrderDescription />

<div class="info-block">
  <p class="info-block-title">Протягом 10 хв ми зв'яжемось з Вами</p>
  <div class="info-block-signs">
    <Call />
    <LocationInfo />
  </div>
</div>

<style lang="scss">
  .info-block {
    margin: rem(70) auto;

    &-signs {
      display: flex;
      justify-content: space-between;
      max-width: rem(300);
      margin: auto;
    }

    &-title {
      @extend %text-center;
      margin-bottom: rem(25);
      font-size: rem(16);
      font-weight: bold;
      color: $color-text-dark;
      text-transform: uppercase;
    }
  }

  .divider-dotted {
    @extend %d-flex;
    @extend %align-center;
    justify-content: center;
  }

  .dot {
    width: rem(6);
    height: rem(6);
    border-radius: 100%;
    background-color: $color-accent;

    & + & {
      margin-left: rem(5);
    }
  }
</style>
