<script>
  import { params } from '../params';
  import IconMap from './ui/icons/Map.svelte';
  import RoadSign from './ui/RoadSign.svelte';

  const query = `${$params.lab_lat}%2C${$params.lab_long}`
  const mapLink = ($params.lab_lat && $params.lab_long) ? 
    `https://www.google.com/maps/search/?api=1&query=${query}`: undefined;
</script>

<a  class="container" href={mapLink} target="_blank">
  <RoadSign>
    <IconMap />
  </RoadSign>

  <p class="text">Ваш маршрут</p>
</a>

<style lang="scss">
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &:hover p {
      text-decoration: underline;
    }
  }

  .text {
    @extend %text;
    width: max-content;
    margin-bottom: rem(5);
    font-size: rem(16);
    font-weight: bold;
    color: $color-text-dark;
    text-transform: uppercase;
  }
</style>
