<script>
  import Tooltip from './Tooltip.svelte';
  export let isActive = false;
  export let isFirst = false;
  export let isHover = false;
  export let isSelectable = false;
  export let getOptionLabel = undefined;
  export let item = undefined;
  export let filterText = '';
  let itemClasses = '';
  $: {
    const classes = [];
    if (isActive) {
      classes.push('active');
    }
    if (isFirst) {
      classes.push('first');
    }
    if (isHover) {
      classes.push('hover');
    }
    if (item.isGroupHeader) {
      classes.push('groupHeader');
    }
    if (item.isGroupItem) {
      classes.push('groupItem');
    }
    if (!isSelectable) {
      classes.push('notSelectable');
    }
    itemClasses = classes.join(' ');
  }
</script>

<div class="item {itemClasses}">
  {@html getOptionLabel(item, filterText)}
  {#if item?.hint}
    <div class="tooltip">
      <Tooltip title={item.hint}>
        <div
          class="info"
          on:click={(e) => {
            e.stopPropagation();
          }}>
          про послугу
        </div>
      </Tooltip>
    </div>
  {/if}
</div>

<style lang="scss">
  .item {
    display: flex;
    justify-content: space-between;
    cursor: default;
    height: var(--height, 42px);
    line-height: var(--height, 42px);
    padding: var(--itemPadding, 0 20px);
    color: var(--itemColor, inherit);
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .groupHeader {
    text-transform: var(--groupTitleTextTransform, uppercase);
  }
  .groupItem {
    padding-left: var(--groupItemPaddingLeft, 40px);
  }
  .item:active {
    background: var(--itemActiveBackground);
  }
  .item.active {
    background: var(--itemIsActiveBG);
    color: var(--itemIsActiveColor);
  }
  .item.notSelectable {
    color: var(--itemIsNotSelectableColor);
  }
  .item.first {
    border-radius: var(--itemFirstBorderRadius, 4px 4px 0 0);
  }
  .item.hover:not(.active) {
    background: var(--itemHoverBG);
    color: var(--itemHoverColor, inherit);
  }

  .info {
    @extend %info;
    position: relative;
    padding-right: 8px;
  }

  .tooltip {
    display: flex;
    align-items: center;
  }
</style>
