import { derived, get } from 'svelte/store';
import { query } from 'svelte-pathfinder';
import { personTypes, paymentTypes, MAX_TIME, MIN_TIME } from './constants';
import dayjs from 'dayjs';

const currentTime = dayjs().format('HH:mm');

const defaultParams = {
  vehicle_category: null,
  trailer_category: null,
  searchValue: '',
  date: dayjs().add(currentTime > MAX_TIME ? 1 : 0, 'hour')
    .startOf('day').format('YYYY-MM-DD'),
  time: currentTime > MAX_TIME ? MIN_TIME : currentTime,
  person_type: personTypes.legal,
  payment_type: paymentTypes.cash,
  inn: '',
  person_name: '',
  order_id: '',
  lab: null,
  lab_lat: '',
  lab_long: '',
  phone_number: '',
  vehicle_no: '',
  trailer_no: '',
  email: '',
  order_amount: undefined,
};

const filterDefaultParams = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => value !== defaultParams[key])
  );
};

export const params = {
  ...derived(query, ($query, set) =>
    set({
      ...defaultParams,
      ...$query.params
    })
  ),
  set: (params) =>
    query.set({
      ...get(query),
      params: filterDefaultParams(params)
    }),
  update: (cb) =>
    query.set({
      ...get(query),
      params: filterDefaultParams(cb(get(query).params))
    })
};
