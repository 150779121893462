<script>
  export let message = 'Default message';
</script>

<p class="popup-message">{message}</p>

<style lang="scss">
  .popup-message {
    @extend %text;
    @extend %text-center;
    @extend %font-bold;
    color: $color-text-dark;
  }
</style>
