<script>
  import Select from 'svelte-select';
  import { params } from '../params';
  import {
    labListStore,
    selectedLab,
    labStore,
    region,
    city,
    regionsStore,
    citiesStore
  } from '../api';
  import {
    fieldValidator,
    validators,
    validationGroup
  } from '../utils/validator';

  import IconMarker from './ui/icons/Marker.svelte';
  import LabSelectItem from './ui/LabSelectItem.svelte';
  import { onMount } from 'svelte';

  const labValidator = fieldValidator(
    validators.required('Виберіть лабораторію')
  );
  $: labValidator.validate(null, $params.lab);

  $: group = validationGroup({
    labValidator,
  });

  onMount(() => {
    document.getElementById('lab_select').addEventListener('input', (e) => {
      $params.searchValue = String(e.target.value);
    });
  });

  export const validate = () => {
    const errors = group.validate();
    return Object.keys(errors).length === 0;
  };
</script>

<div class="form-row">
  <Select
    value={$region}
    Icon={IconMarker}
    showIndicator
    items={$regionsStore}
    placeholder={'Виберіть область'}
    noOptionsMessage={'Немає лабораторій які надають обрані послуги'}
    on:select={(e) => {
      $region = e.detail.value;
      $city = null;
    }}
    on:clear={() => {
      $region = null;
      $city = null;
    }} />
</div>

<div class="form-row">
  <Select
    value={$city}
    Icon={IconMarker}
    showIndicator
    isDisabled={!$region}
    items={$citiesStore}
    placeholder={'Виберіть населенный пункт'}
    noOptionsMessage={'Немає лабораторій які надають обрані послуги'}
    on:select={(e) => {
      $city = e.detail.value;
    }}
    on:clear={() => {
      $city = null;
    }} />
</div>

<div class="form-row">
  <Select
    id={'lab_select'}
    Icon={IconMarker}
    Item={LabSelectItem}
    showIndicator
    value={$selectedLab}
    items={$labListStore}
    itemFilter={() => true}
    optionIdentifier={'id'}
    labelIdentifier={'desc'}
    placeholder={'Виберіть лабораторію'}
    noOptionsMessage={'Немає лабораторій які надають обрані послуги'}
    hideEmptyState={!$labStore.length}
    on:select={(e) => ($params.lab = e.detail.id)}
    on:clear={() => {
      $params.lab = null;
      $params.searchValue = '';
    }} />

  <p class="error-msg" class:hidden={!$labValidator.showMessage}>
    {$labValidator.message}
  </p>
</div>

<style lang="scss">
  .input-icon input {
    padding-left: rem(50);
  }

  .services {
    display: flex;
    gap: rem(10);
  }

  .service-row {
    display: flex;
    gap: rem(10);
  }

  .datetime {
    display: flex;
    gap: rem(10);
  }

  .field-label {
    @extend %text;
    @extend %text-left;
    margin-bottom: rem(5);
    font-size: rem(12);
  }

  .field {
    @extend %w-full;
    margin: 0;
    padding-left: 50px;
  }

  .hidden {
    display: none;
  }

  .error-msg {
    @extend %error-msg;
  }
</style>