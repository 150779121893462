export const holdStore = (...stores) => {
  const unsubList = [];
  stores.forEach((store) => {
    unsubList.push(store.subscribe(() => {}));
  });

  return () => {
    unsubList.forEach((unsub) => unsub());
  };
};
