<svg
  width="22"
  height="20"
  viewBox="0 0 22 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M18.9265 2.53113H17.671V1.52616C17.671 1.23677 17.4366 1 17.15 1C16.8635 1 16.6291 1.23677 16.6291 1.52616V2.53113H11.5236V1.52616C11.5236 1.23677 11.2891 1 11.0026 1C10.7161 1 10.4816 1.23677 10.4816 1.52616V2.53113H5.32404V1.52616C5.32404 1.23677 5.0896 1 4.80307 1C4.51654 1 4.2821 1.23677 4.2821 1.52616V2.53113H3.07866C1.93253 2.53113 1 3.50453 1 4.66209V16.8954C1 18.0582 1.92732 19 3.07866 19H18.9213C20.0727 19 21 18.0582 21 16.9006V4.66209C21.0052 3.50453 20.0727 2.53113 18.9265 2.53113ZM19.9633 16.8954C19.9633 17.4741 19.5048 17.9424 18.9317 17.9477H3.07866C2.5056 17.9477 2.04194 17.4794 2.04194 16.9006V4.66209C2.04194 4.07805 2.5056 3.58346 3.07866 3.58346H4.2821V4.34639C4.2821 4.63578 4.51654 4.87255 4.80307 4.87255C5.0896 4.87255 5.32404 4.63578 5.32404 4.34639V3.58346H10.4816V4.34639C10.4816 4.63578 10.7161 4.87255 11.0026 4.87255C11.2891 4.87255 11.5236 4.63578 11.5236 4.34639V3.58346H16.6291V4.34639C16.6291 4.63578 16.8635 4.87255 17.15 4.87255C17.4366 4.87255 17.671 4.63578 17.671 4.34639V3.58346H18.9265C19.5048 3.58346 19.9633 4.08331 19.9633 4.66209V16.8954Z"
    fill="black" />
  <path
    d="M9.63245 7.00351H8.72075C8.43422 7.00351 8.19978 7.24028 8.19978 7.52967C8.19978 7.81906 8.43422 8.05583 8.72075 8.05583H9.63245C9.91898 8.05583 10.1534 7.81906 10.1534 7.52967C10.1534 7.24028 9.91898 7.00351 9.63245 7.00351Z"
    fill="black" />
  <path
    d="M13.2584 7.00351H12.3467C12.0602 7.00351 11.8257 7.24028 11.8257 7.52967C11.8257 7.81906 12.0602 8.05583 12.3467 8.05583H13.2584C13.5449 8.05583 13.7794 7.81906 13.7794 7.52967C13.7794 7.24028 13.5501 7.00351 13.2584 7.00351Z"
    fill="black" />
  <path
    d="M16.9156 7.00351H16.0039C15.7174 7.00351 15.4829 7.24028 15.4829 7.52967C15.4829 7.81906 15.7174 8.05583 16.0039 8.05583H16.9156C17.2021 8.05583 17.4366 7.81906 17.4366 7.52967C17.4366 7.24028 17.2021 7.00351 16.9156 7.00351Z"
    fill="black" />
  <path
    d="M9.63245 10.634H8.72075C8.43422 10.634 8.19978 10.8708 8.19978 11.1602C8.19978 11.4496 8.43422 11.6863 8.72075 11.6863H9.63245C9.91898 11.6863 10.1534 11.4496 10.1534 11.1602C10.1534 10.8708 9.91898 10.634 9.63245 10.634Z"
    fill="black" />
  <path
    d="M5.98046 10.634H5.06355C4.77702 10.634 4.54259 10.8708 4.54259 11.1602C4.54259 11.4496 4.77702 11.6863 5.06355 11.6863H5.97525C6.26178 11.6863 6.49622 11.4496 6.49622 11.1602C6.49622 10.8708 6.26699 10.634 5.98046 10.634Z"
    fill="black" />
  <path
    d="M13.2584 10.634H12.3467C12.0602 10.634 11.8257 10.8708 11.8257 11.1602C11.8257 11.4496 12.0602 11.6863 12.3467 11.6863H13.2584C13.5449 11.6863 13.7794 11.4496 13.7794 11.1602C13.7794 10.8708 13.5501 10.634 13.2584 10.634Z"
    fill="black" />
  <path
    d="M16.9156 10.634H16.0039C15.7174 10.634 15.4829 10.8708 15.4829 11.1602C15.4829 11.4496 15.7174 11.6863 16.0039 11.6863H16.9156C17.2021 11.6863 17.4366 11.4496 17.4366 11.1602C17.4366 10.8708 17.2021 10.634 16.9156 10.634Z"
    fill="black" />
  <path
    d="M9.63245 14.2119H8.72075C8.43422 14.2119 8.19978 14.4487 8.19978 14.7381C8.19978 15.0275 8.43422 15.2643 8.72075 15.2643H9.63245C9.91898 15.2643 10.1534 15.0275 10.1534 14.7381C10.1534 14.4487 9.91898 14.2119 9.63245 14.2119Z"
    fill="black" />
  <path
    d="M5.98046 14.2119H5.06355C4.77702 14.2119 4.54259 14.4487 4.54259 14.7381C4.54259 15.0275 4.77702 15.2643 5.06355 15.2643H5.97525C6.26178 15.2643 6.49622 15.0275 6.49622 14.7381C6.49622 14.4487 6.26699 14.2119 5.98046 14.2119Z"
    fill="black" />
  <path
    d="M13.2584 14.2119H12.3467C12.0602 14.2119 11.8257 14.4487 11.8257 14.7381C11.8257 15.0275 12.0602 15.2643 12.3467 15.2643H13.2584C13.5449 15.2643 13.7794 15.0275 13.7794 14.7381C13.7794 14.4487 13.5501 14.2119 13.2584 14.2119Z"
    fill="black" />
  <path
    d="M18.9265 2.53113H17.671V1.52616C17.671 1.23677 17.4366 1 17.15 1C16.8635 1 16.6291 1.23677 16.6291 1.52616V2.53113H11.5236V1.52616C11.5236 1.23677 11.2891 1 11.0026 1C10.7161 1 10.4816 1.23677 10.4816 1.52616V2.53113H5.32404V1.52616C5.32404 1.23677 5.0896 1 4.80307 1C4.51654 1 4.2821 1.23677 4.2821 1.52616V2.53113H3.07866C1.93253 2.53113 1 3.50453 1 4.66209V16.8954C1 18.0582 1.92732 19 3.07866 19H18.9213C20.0727 19 21 18.0582 21 16.9006V4.66209C21.0052 3.50453 20.0727 2.53113 18.9265 2.53113ZM19.9633 16.8954C19.9633 17.4741 19.5048 17.9424 18.9317 17.9477H3.07866C2.5056 17.9477 2.04194 17.4794 2.04194 16.9006V4.66209C2.04194 4.07805 2.5056 3.58346 3.07866 3.58346H4.2821V4.34639C4.2821 4.63578 4.51654 4.87255 4.80307 4.87255C5.0896 4.87255 5.32404 4.63578 5.32404 4.34639V3.58346H10.4816V4.34639C10.4816 4.63578 10.7161 4.87255 11.0026 4.87255C11.2891 4.87255 11.5236 4.63578 11.5236 4.34639V3.58346H16.6291V4.34639C16.6291 4.63578 16.8635 4.87255 17.15 4.87255C17.4366 4.87255 17.671 4.63578 17.671 4.34639V3.58346H18.9265C19.5048 3.58346 19.9633 4.08331 19.9633 4.66209V16.8954Z"
    stroke="black"
    stroke-width="0.5" />
  <path
    d="M9.63245 7.00351H8.72075C8.43422 7.00351 8.19978 7.24028 8.19978 7.52967C8.19978 7.81906 8.43422 8.05583 8.72075 8.05583H9.63245C9.91898 8.05583 10.1534 7.81906 10.1534 7.52967C10.1534 7.24028 9.91898 7.00351 9.63245 7.00351Z"
    stroke="black"
    stroke-width="0.5" />
  <path
    d="M13.2584 7.00351H12.3467C12.0602 7.00351 11.8257 7.24028 11.8257 7.52967C11.8257 7.81906 12.0602 8.05583 12.3467 8.05583H13.2584C13.5449 8.05583 13.7794 7.81906 13.7794 7.52967C13.7794 7.24028 13.5501 7.00351 13.2584 7.00351Z"
    stroke="black"
    stroke-width="0.5" />
  <path
    d="M16.9156 7.00351H16.0039C15.7174 7.00351 15.4829 7.24028 15.4829 7.52967C15.4829 7.81906 15.7174 8.05583 16.0039 8.05583H16.9156C17.2021 8.05583 17.4366 7.81906 17.4366 7.52967C17.4366 7.24028 17.2021 7.00351 16.9156 7.00351Z"
    stroke="black"
    stroke-width="0.5" />
  <path
    d="M9.63245 10.634H8.72075C8.43422 10.634 8.19978 10.8708 8.19978 11.1602C8.19978 11.4496 8.43422 11.6863 8.72075 11.6863H9.63245C9.91898 11.6863 10.1534 11.4496 10.1534 11.1602C10.1534 10.8708 9.91898 10.634 9.63245 10.634Z"
    stroke="black"
    stroke-width="0.5" />
  <path
    d="M5.98046 10.634H5.06355C4.77702 10.634 4.54259 10.8708 4.54259 11.1602C4.54259 11.4496 4.77702 11.6863 5.06355 11.6863H5.97525C6.26178 11.6863 6.49622 11.4496 6.49622 11.1602C6.49622 10.8708 6.26699 10.634 5.98046 10.634Z"
    stroke="black"
    stroke-width="0.5" />
  <path
    d="M13.2584 10.634H12.3467C12.0602 10.634 11.8257 10.8708 11.8257 11.1602C11.8257 11.4496 12.0602 11.6863 12.3467 11.6863H13.2584C13.5449 11.6863 13.7794 11.4496 13.7794 11.1602C13.7794 10.8708 13.5501 10.634 13.2584 10.634Z"
    stroke="black"
    stroke-width="0.5" />
  <path
    d="M16.9156 10.634H16.0039C15.7174 10.634 15.4829 10.8708 15.4829 11.1602C15.4829 11.4496 15.7174 11.6863 16.0039 11.6863H16.9156C17.2021 11.6863 17.4366 11.4496 17.4366 11.1602C17.4366 10.8708 17.2021 10.634 16.9156 10.634Z"
    stroke="black"
    stroke-width="0.5" />
  <path
    d="M9.63245 14.2119H8.72075C8.43422 14.2119 8.19978 14.4487 8.19978 14.7381C8.19978 15.0275 8.43422 15.2643 8.72075 15.2643H9.63245C9.91898 15.2643 10.1534 15.0275 10.1534 14.7381C10.1534 14.4487 9.91898 14.2119 9.63245 14.2119Z"
    stroke="black"
    stroke-width="0.5" />
  <path
    d="M5.98046 14.2119H5.06355C4.77702 14.2119 4.54259 14.4487 4.54259 14.7381C4.54259 15.0275 4.77702 15.2643 5.06355 15.2643H5.97525C6.26178 15.2643 6.49622 15.0275 6.49622 14.7381C6.49622 14.4487 6.26699 14.2119 5.98046 14.2119Z"
    stroke="black"
    stroke-width="0.5" />
  <path
    d="M13.2584 14.2119H12.3467C12.0602 14.2119 11.8257 14.4487 11.8257 14.7381C11.8257 15.0275 12.0602 15.2643 12.3467 15.2643H13.2584C13.5449 15.2643 13.7794 15.0275 13.7794 14.7381C13.7794 14.4487 13.5501 14.2119 13.2584 14.2119Z"
    stroke="black"
    stroke-width="0.5" />
</svg>
