<script>
  import { imask } from '@imask/svelte';
  import PersonType from './PersonType.svelte';
  import { params } from '../params';
  import { paymentTypes, personTypes } from '../constants';
  import {
    fieldValidator,
    validators,
    validationGroup
  } from '../utils/validator';
  import Select from 'svelte-select';
  import IconDoc from './ui/icons/Doc.svelte';
  import IconMoney from './ui/icons/Money.svelte';
  import IconUser from './ui/icons/User.svelte';
  import IconPhone from './ui/icons/Phone.svelte';
  import IconAuto from './ui/icons/Auto.svelte';
  import IconPryc from './ui/icons/Pryc.svelte';
  import IconEmail from './ui/icons/Email.svelte';

  let phone_number = $params.phone_number;

  const innValidator = fieldValidator(validators.required(), validators.inn());

  const legalPersonNameValidator = fieldValidator();
  const individualPersonNameValidator = fieldValidator(validators.required());
  $: personNameValidator =
    $params.person_type === personTypes.legal
      ? legalPersonNameValidator
      : individualPersonNameValidator;

  const phoneValidator = fieldValidator(validators.required());
  const emailValidator = fieldValidator(
    validators.required(),
    validators.email()
  );

  $: group = validationGroup({
    personNameValidator,
    innValidator,
    phoneValidator,
    emailValidator
  });
  let paymentDescription = [
    { type: 'cash', desc: 'Готівкова оплата' },
    { type: 'cashless', desc: 'Безготівкова оплата' }
    //{ type: 'online', desc: 'Online оплата' }
  ];

  export const validate = () => {
    const errors = group.validate();
    if ($params.person_type !== personTypes.legal) {
      delete errors.innValidator;
    }
    if ($params.payment_type !== paymentTypes.cashless) {
      delete errors.emailValidator;
    }
    return Object.keys(errors).length === 0;
  };
</script>

<h2 class="step-title">Замовляю послугу як:</h2>

<div class="form-row">
  <PersonType />
</div>

<div class="form-row" class:hidden={$params.person_type !== personTypes.legal}>
  <div class="input-icon">
    <div class="icon">
      <IconDoc />
    </div>
    <input
      id="inn"
      class:invalid={$innValidator.showMessage}
      type="number"
      bind:value={$params.inn}
      use:innValidator.validate={$params.inn}
      placeholder="ЕДРПОУ / ИНН" />
  </div>

  <p class="error-msg" class:hidden={!$innValidator.showMessage}>
    {$innValidator.message}
  </p>
</div>

<div class="form-row">
  <div class="input-icon">
    <div class="icon">
      <IconUser />
    </div>
    <input
      id="person_name"
      type="text"
      class:invalid={$personNameValidator.showMessage}
      bind:value={$params.person_name}
      use:legalPersonNameValidator.validate={$params.person_name}
      use:individualPersonNameValidator.validate={$params.person_name}
      placeholder="Контактна особа" />
  </div>
  <p class="error-msg" class:hidden={!$personNameValidator.showMessage}>
    {$personNameValidator.message}
  </p>
</div>

<div class="form-row">
  <div class="input-icon">
    <div class="icon">
      <IconPhone />
    </div>
    <input
      id="phone"
      type="tel"
      class:invalid={$phoneValidator.showMessage}
      value={phone_number}
      use:phoneValidator.validate={$params.phone_number}
      use:imask={{
        mask: '+{38} 000 000-00-00',
        lazy: false
      }}
      on:complete={({ detail: maskRef }) =>
        ($params.phone_number = maskRef.value)}
      on:accept={() => ($params.phone_number = '')}
      placeholder="Номер телефону" />
  </div>
  <p class="error-msg" class:hidden={!$phoneValidator.showMessage}>
    {$phoneValidator.message}
  </p>
</div>

<div class="divider" />

<div class="form-row">
  <Select
    id="payment_type"
    value={paymentDescription.find(
      (item) => item.type === $params.payment_type
    )}
    items={paymentDescription}
    optionIdentifier={'type'}
    labelIdentifier={'desc'}
    Icon={IconMoney}
    showIndicator
    isClearable={false}
    isSearchable={false}
    placeholder={'Форма оплати'}
    on:select={(e) => ($params.payment_type = e.detail.type)} />
</div>

<div
  class="form-row"
  class:hidden={$params.payment_type !== paymentTypes.cashless}>
  <div class="input-icon">
    <div class="icon">
      <IconEmail />
    </div>
    <input
      id="email"
      class:invalid={$emailValidator.showMessage}
      bind:value={$params.email}
      use:emailValidator.validate={$params.email}
      on:blur={() => emailValidator.toggleMessage(true)}
      placeholder="E-mail" />
  </div>
  <p class="error-msg" class:hidden={!$emailValidator.showMessage}>
    {$emailValidator.message}
  </p>
</div>

<style lang="scss">
  .step-title {
    @extend %title;
    margin: rem(15) 0 rem(10);
  }

  input {
    @extend %input;
    width: 100%;
    margin: 0;
    padding: rem(10);

    &[type='number']::-webkit-outer-spin-button,
    &[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .input-icon input {
    padding-left: rem(50);
  }

  .hidden {
    display: none;
  }

  .invalid {
    border-color: $color-urgent !important;
  }

  .error-msg {
    @extend %error-msg;
  }

  .divider {
    @extend %w-full;
    height: 1px;
    display: block;
    margin: 0;
    border-top: 1px solid $color-divider;
  }
</style>
