<script>
  import { params } from '../params';
  import dayjs from 'dayjs';

  $: formatDate = dayjs($params.date, 'YYYY-MM-DD').format('DD.MM.YYYY');
  const EMPTY_VALUE = '-'
</script>

<ul class="summary-list">
  <li class="summary-item">
    <span class="summary-item-label">Номер замовлення: </span>
    <span class="summary-item-value"> {$params.order_id || EMPTY_VALUE}</span>
  </li>
  <li class="summary-item">
    <span class="summary-item-label">Послуга: </span>
    <span class="summary-item-value"> {$params.services || EMPTY_VALUE}</span>
  </li>
  <li class="summary-item">
    <span class="summary-item-label">Лабораторія: </span>
    <span class="summary-item-value"> {$params.lab_info || EMPTY_VALUE}</span>
  </li>
  <li class="summary-item">
    <span class="summary-item-label">Дата візиту: </span>
    <span class="summary-item-value">{formatDate || EMPTY_VALUE}</span>
  </li>
  <li class="summary-item">
    <span class="summary-item-label">Автомобіль: </span>
    <span class="summary-item-value">{$params.vehicle_no || EMPTY_VALUE}</span>
  </li>
  <li class="summary-item">
    <span class="summary-item-label">Замовник: </span>
    <span class="summary-item-value"
      >{`${$params.inn} ${$params.person_name || EMPTY_VALUE}`}</span>
  </li>
  <li class="summary-item">
    <span class="summary-item-label">Телефон: </span>
    <span class="summary-item-value">
      {$params.phone_number || EMPTY_VALUE}
    </span>
  </li>
  <li class="summary-item">
    <span class="summary-item-label">Сума замовлення: </span>
    <span class="summary-item-value">
      {`${$params.order_amount || EMPTY_VALUE} грн`}
    </span>
  </li>
</ul>

<style lang="scss">
  .summary-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .summary-item {
    @extend %d-flex;
    @extend %align-center;
    @extend %text;
    padding-left: rem(25);
    position: relative;

    &::before {
      content: '';
      width: rem(7);
      height: rem(7);
      border-radius: 100%;
      border: 1px solid $color-border-dark;
      background-color: $color-accent;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    & + & {
      margin-top: rem(10);
    }

    &-label {
      margin-right: rem(5);
    }

    &-value {
      font-weight: bold;
    }
  }
</style>
