<script>
  import OrderDescription from './OrderDescription.svelte';
  import LocationInfo from './LocationInfo.svelte';
  import OrderSummaryHeader from './OrderSummaryHeader.svelte';
  import Call from './Call.svelte';
</script>

<OrderSummaryHeader />
<OrderDescription />
<div class="info-block">
  <Call />
  <LocationInfo />
</div>

<style lang="scss">
  .info-block {
    margin: rem(40) auto;
    display: flex;
    justify-content: space-between;
    max-width: rem(300);
  }
</style>
