<svg
  width="20"
  height="16"
  viewBox="0 0 20 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M17.6562 0H2.34375C1.05141 0 0 1.03523 0 2.30769V4.21135L8.60121 10.5106C9.01953 10.817 9.50977 10.9702 10 10.9702C10.4902 10.9702 10.9805 10.817 11.3988 10.5106L20 4.21135V2.30769C20 1.03523 18.9486 0 17.6562 0ZM18.4375 3.43831L10.4663 9.27615C10.1874 9.48042 9.81262 9.48042 9.53375 9.27615L1.5625 3.43831V2.30769C1.5625 1.88354 1.91297 1.53846 2.34375 1.53846H17.6562C18.087 1.53846 18.4375 1.88354 18.4375 2.30769V3.43831ZM18.4375 7.27304L20 6.12873V13.6923C20 14.9648 18.9486 16 17.6562 16H2.34375C1.05141 16 0 14.9648 0 13.6923V6.12873L1.5625 7.27304V13.6923C1.5625 14.1165 1.91297 14.4615 2.34375 14.4615H17.6562C18.087 14.4615 18.4375 14.1165 18.4375 13.6923V7.27304Z"
    fill="black" />
</svg>
