<script>
  import IconCalling from './ui/icons/Call-center.svelte';
  import RoadSign from './ui/RoadSign.svelte';
  import { CALL_CENTER_NUMBER } from '../constants';
</script>

<a class="container" href="tel:{CALL_CENTER_NUMBER}">
  <RoadSign>
    <IconCalling />
  </RoadSign>

  <p class="text">{CALL_CENTER_NUMBER}</p>
</a>

<style lang="scss">
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &:hover a {
      text-decoration: underline;
    }
  }

  .text {
    @extend %text;
    width: max-content;
    margin-bottom: rem(5);
    font-size: rem(16);
    font-weight: bold;
    color: $color-text-dark;
    text-transform: uppercase;
  }
</style>

