<h2 class="step-title">
  <span class="step-title-inner">Дякуємо за Ваше замовлення</span>
</h2>

<style lang="scss">
  .step-title {
    @extend %title;
    margin: rem(15) auto rem(30);
    text-align: center;
    text-transform: uppercase;

    &-inner {
      display: inline-block;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        @extend %w-full;
        height: 3px;
        background-color: $color-accent;
      }
    }
  }
</style>
