<script>
  import { priceStore } from '../api';
  import { params } from '../params';
  $: $params.order_amount = $priceStore?.amount;
</script>

{#if $priceStore}
  <div class="form-row">
    <p class="order-amount">
      Сума замовлення: <span class="order-price">{$priceStore.amount} грн</span>
    </p>
  </div>
{/if}

<style lang="scss">
  .order-amount {
    @extend %text;
    @extend %w-full;
    @extend %d-flex;
    @extend %align-center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }

  .order-price {
    @extend %font-bold;
  }
</style>
